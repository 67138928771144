.main_page_desktop {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0rem 2.5%;
    /* border: 2px solid blue; */
}

.main_page_mobile {
    /* border: 2px solid red; */
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 4rem; */
}
